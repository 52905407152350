<template>
  <div class="title-table mb-2 ps-3 py-1 fw-bold">
    {{ $t(`Transaction.table.title`) }}
  </div>
  <!-- Фільтр транзакцій -->
  <div class="toolbar">
    <div class="btn-group mb-2">
      <button
        class="btn btn-outline-primary btn-sm dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
        data-bs-auto-close="outside"
        aria-expanded="false"
        ref="dropdownBtnFilter"
      >
        <i class="bi bi-filter-square me-2"></i>
        <span>{{ $t(`uiElements.btn.filters`) }}</span>
      </button>
      <button
        class="btn btn-outline-primary btn-sm"
        type="button"
        @click="resetFilterForm"
      >
        <i class="bi bi-x-square"></i>
      </button>
      <button
        class="btn btn-outline-primary btn-sm"
        type="button"
        @click="showModalViewTableSettings"
        title="Налаштування вигляду таблиці"
      >
        <i class="bi bi-table"></i>
      </button>
      <form
        class="dropdown-menu p-4"
        style="width: 500px"
        @submit.prevent="formFilterTr"
        ref="dropdownFormFilter"
      >
        <div class="row mb-3">
          <label
            for="transactionId-ff"
            class="col-sm-4 col-form-label col-form-label-sm"
            >{{ $t(`Transaction.table.transactionId`) }}</label
          >
          <div class="col-sm-8">
            <input
              v-model.trim="transactionId"
              type="text"
              class="form-control form-control-sm"
              id="transactionId-ff"
              :placeholder="$t(`Validations.notChosen`)"
            />
          </div>
        </div>
        <div class="row mb-3">
          <label
            for="panMasked-ff"
            class="col-sm-4 col-form-label col-form-label-sm"
            >{{ $t(`Transaction.table.panMasked`) }}</label
          >
          <div class="col-sm-8">
            <input
              v-model.trim="panMasked"
              type="text"
              class="form-control form-control-sm"
              id="panMasked-ff"
              :placeholder="$t(`Validations.notChosen`)"
            />
          </div>
        </div>
        <div class="row mb-3">
          <label
            for="terminalId-ff"
            class="col-sm-4 col-form-label col-form-label-sm"
            >{{ $t(`Transaction.table.terminalId`) }}</label
          >
          <div class="col-sm-8">
            <input
              v-model.trim="terminalId"
              type="text"
              class="form-control form-control-sm"
              id="terminalId-ff"
              :placeholder="$t(`Validations.notChosen`)"
            />
          </div>
        </div>
        <div class="row mb-3">
          <label
            for="approvalCode-ff"
            class="col-sm-4 col-form-label col-form-label-sm"
            >{{ $t(`Transaction.table.approvalCode`) }}</label
          >
          <div class="col-sm-8">
            <input
              v-model.trim="approvalCode"
              type="text"
              class="form-control form-control-sm"
              id="approvalCode-ff"
              :placeholder="$t(`Validations.notChosen`)"
            />
          </div>
        </div>
        <div class="row mb-3">
          <label
            for="rrn-ff"
            class="col-sm-4 col-form-label col-form-label-sm"
            >{{ $t(`Transaction.table.rrn`) }}</label
          >
          <div class="col-sm-8">
            <input
              v-model.trim="rrn"
              type="text"
              class="form-control form-control-sm"
              id="rrn-ff"
              :placeholder="$t(`Validations.notChosen`)"
            />
          </div>
        </div>
        <div class="row mb-3">
          <label
            for="operation-ff"
            class="col-sm-4 col-form-label col-form-label-sm"
            >{{ $t(`Transaction.table.operation`) }}</label
          >
          <div class="col-sm-8">
            <select
              class="form-select form-select-sm"
              id="status-ff"
              v-model.trim="operation"
            >
              <option value="" selected>
                {{ $t(`Validations.notChosen`) }}
              </option>
              <option value="ACTIVE">
                {{ $t(`Terminal.selectStatus.ACTIVE`) }}
              </option>
              <option v-for="item in operationList" :key="item" :value="item">
                {{ item }}
              </option>
            </select>
          </div>
        </div>

        <div class="justify-content-end d-flex">
          <button
            class="btn btn-outline-primary btn-sm me-3"
            type="button"
            @click="resetFilterForm"
          >
            <i class="bi bi-x-square me-1"></i>
            <span>{{ $t(`uiElements.btn.resetFilters`) }}</span>
          </button>
          <button type="submit" class="btn btn-primary btn-sm">
            <i class="bi bi-clipboard-check me-1"></i>
            <span>{{ $t(`uiElements.btn.apply`) }}</span>
          </button>
        </div>
      </form>
    </div>
  </div>

  <!-- Фільтр транзакцій кінець -->

  <div
    class="table-responsive table-block overflow-auto"
    id="divTableTransaction"
    @scroll="scrollTable"
  >
    <div class="row g-0">
      <div class="col-auto">
        <table
          class="table table-striped table-hover table-sm table-light caption-top"
        >
          <thead class="thead-sort">
            <tr class="text-center">
              <th
                scope="col"
                data-col-sort="fraudMonitoring"
                v-show="
                  columnList.find((item) => item.key === 'fraudMonitoring')
                    .visible
                "
                @click="clickSortCol($event, 'fraudMonitoring')"
                :title="$t(`Transaction.table.fraudMonitoringFull`)"
              >
                {{ $t(`Transaction.table.fraudMonitoring`) }}
                <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
              </th>
              <th
                scope="col"
                data-col-sort="transactionId"
                v-show="
                  columnList.find((item) => item.key === 'transactionId')
                    .visible
                "
                @click="clickSortCol($event, 'transactionId')"
              >
                {{ $t(`Transaction.table.transactionId`) }}
                <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
              </th>
              <th
                scope="col"
                data-col-sort="operation"
                v-show="
                  columnList.find((item) => item.key === 'operation').visible
                "
                @click="clickSortCol($event, 'operation')"
              >
                {{ $t(`Transaction.table.operation`) }}
                <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
              </th>
              <th
                scope="col"
                data-col-sort="panMasked"
                v-show="
                  columnList.find((item) => item.key === 'panMasked').visible
                "
                @click="clickSortCol($event, 'panMasked')"
              >
                {{ $t(`Transaction.table.panMasked`) }}
                <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
              </th>
              <th
                scope="col"
                data-col-sort="transactionDate"
                v-show="
                  columnList.find((item) => item.key === 'transactionDate')
                    .visible
                "
                @click="clickSortCol($event, 'transactionDate')"
              >
                {{ $t(`Transaction.table.transactionDate`) }}
                <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
              </th>
              <th
                scope="col"
                data-col-sort="localTransactionDate"
                v-show="
                  columnList.find((item) => item.key === 'localTransactionDate')
                    .visible
                "
                @click="clickSortCol($event, 'localTransactionDate')"
              >
                {{ $t(`Transaction.table.localTransactionDate`) }}
                <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
              </th>
              <th
                scope="col"
                data-col-sort="amount"
                v-show="
                  columnList.find((item) => item.key === 'amount').visible
                "
                @click="clickSortCol($event, 'amount')"
              >
                {{ $t(`Transaction.table.amount`) }}
                <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
              </th>
              <th
                scope="col"
                data-col-sort="amountOther"
                v-show="
                  columnList.find((item) => item.key === 'amountOther').visible
                "
                @click="clickSortCol($event, 'amountOther')"
              >
                {{ $t(`Transaction.table.amountOther`) }}
                <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
              </th>
              <th
                scope="col"
                data-col-sort="currency"
                v-show="
                  columnList.find((item) => item.key === 'currency').visible
                "
                @click="clickSortCol($event, 'currency')"
              >
                {{ $t(`Transaction.table.currency`) }}
                <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
              </th>
              <th
                scope="col"
                v-show="
                  columnList.find((item) => item.key === 'terminalId').visible
                "
              >
                {{ $t(`Transaction.table.terminalId`) }}
                <div class="icon-sort"><i class="bi bi-app"></i></div>
              </th>
              <th
                scope="col"
                v-show="
                  columnList.find((item) => item.key === 'deviceName').visible
                "
              >
                {{ $t(`Transaction.table.deviceName`) }}
                <div class="icon-sort"><i class="bi bi-app"></i></div>
              </th>
              <th
                scope="col"
                data-col-sort="formFactor"
                v-show="
                  columnList.find((item) => item.key === 'formFactor').visible
                "
                @click="clickSortCol($event, 'formFactor')"
              >
                {{ $t(`Transaction.table.formFactor`) }}
                <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
              </th>
              <th
                scope="col"
                data-col-sort="approvalCode"
                v-show="
                  columnList.find((item) => item.key === 'approvalCode').visible
                "
                @click="clickSortCol($event, 'approvalCode')"
              >
                {{ $t(`Transaction.table.approvalCode`) }}
                <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
              </th>
              <th
                scope="col"
                data-col-sort="responseCode"
                v-show="
                  columnList.find((item) => item.key === 'responseCode').visible
                "
                @click="clickSortCol($event, 'responseCode')"
              >
                {{ $t(`Transaction.table.responseCode`) }}
                <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
              </th>
              <th
                scope="col"
                data-col-sort="rrn"
                v-show="columnList.find((item) => item.key === 'rrn').visible"
                @click="clickSortCol($event, 'rrn')"
              >
                {{ $t(`Transaction.table.rrn`) }}
                <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
              </th>
              <th
                scope="col"
                data-col-sort="statusCode"
                v-show="
                  columnList.find((item) => item.key === 'statusCode').visible
                "
                @click="clickSortCol($event, 'statusCode')"
              >
                {{ $t(`Transaction.table.statusCode`) }}
                <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
              </th>
              <th
                scope="col"
                data-col-sort="receiptNumber"
                v-show="
                  columnList.find((item) => item.key === 'receiptNumber')
                    .visible
                "
                @click="clickSortCol($event, 'receiptNumber')"
              >
                {{ $t(`Transaction.table.receiptNumber`) }}
                <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
              </th>
              <th
                scope="col"
                data-col-sort="responseDate"
                v-show="
                  columnList.find((item) => item.key === 'responseDate').visible
                "
                @click="clickSortCol($event, 'responseDate')"
              >
                {{ $t(`Transaction.table.responseDate`) }}
                <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
              </th>
              <th
                scope="col"
                v-show="
                  columnList.find((item) => item.key === 'geoposition').visible
                "
              >
                {{ $t(`Transaction.table.geoposition`) }}
                <div class="icon-sort"><i class="bi bi-app"></i></div>
              </th>
            </tr>
          </thead>
          <tbody class="text-nowrap">
            <template v-if="isLoading">
              <tr>
                <td colspan="16">
                  <div class="loading text-center">
                    <trn-loading></trn-loading>
                  </div>
                </td>
              </tr>
            </template>
            <template v-if="dataResp">
              <tr
                v-for="item in dataResp.content"
                :key="item"
                class="text-center"
              >
                <td
                  v-show="
                    columnList.find((item) => item.key === 'fraudMonitoring')
                      .visible
                  "
                >
                  <button
                    type="button"
                    class="btn border"
                    :style="{
                      backgroundColor: item?.fraudMonitoring?.color
                        ? item?.fraudMonitoring?.color
                        : 'white',
                    }"
                    data-bs-toggle="tooltip"
                    data-bs-html="true"
                    :data-bs-title="item?.fraudMonitoring?.estimate"
                  ></button>
                </td>
                <td
                  v-show="
                    columnList.find((item) => item.key === 'transactionId')
                      .visible
                  "
                >
                  {{ item.transactionId }}
                </td>
                <td
                  v-show="
                    columnList.find((item) => item.key === 'operation').visible
                  "
                >
                  {{ item.operation }}
                </td>
                <td
                  v-show="
                    columnList.find((item) => item.key === 'panMasked').visible
                  "
                >
                  {{ item.panMasked }}
                </td>
                <td
                  v-show="
                    columnList.find((item) => item.key === 'transactionDate')
                      .visible
                  "
                >
                  {{ $filters.formatDateTime(item.transactionDate) }}
                </td>
                <td
                  v-show="
                    columnList.find(
                      (item) => item.key === 'localTransactionDate'
                    ).visible
                  "
                >
                  {{ $filters.formatDateTimeUTC(item.localTransactionDate) }}
                </td>
                <td
                  v-show="
                    columnList.find((item) => item.key === 'amount').visible
                  "
                >
                  {{ $filters.formatAmount(item.amount) }}
                </td>
                <td
                  v-show="
                    columnList.find((item) => item.key === 'amountOther')
                      .visible
                  "
                >
                  {{ item.amountOther }}
                </td>
                <td
                  v-show="
                    columnList.find((item) => item.key === 'currency').visible
                  "
                >
                  {{ item.currency ? item.currency.letterCode : "" }}
                </td>
                <td
                  v-show="
                    columnList.find((item) => item.key === 'terminalId').visible
                  "
                >
                  <a href="#" @click="getTerminalId" ref="refTerminalId">{{
                    item.terminal.terminalId ? item.terminal.terminalId : ""
                  }}</a>
                </td>
                <td
                  v-show="
                    columnList.find((item) => item.key === 'deviceName').visible
                  "
                >
                  {{
                    item.terminal.device ? item.terminal.device.deviceName : ""
                  }}
                </td>
                <td
                  v-show="
                    columnList.find((item) => item.key === 'formFactor').visible
                  "
                >
                  {{ item.formFactor }}
                </td>
                <td
                  v-show="
                    columnList.find((item) => item.key === 'approvalCode')
                      .visible
                  "
                >
                  {{ item.approvalCode }}
                </td>
                <td
                  v-show="
                    columnList.find((item) => item.key === 'responseCode')
                      .visible
                  "
                >
                  {{ item.responseCode }}
                </td>
                <td
                  v-show="columnList.find((item) => item.key === 'rrn').visible"
                >
                  {{ item.rrn }}
                </td>
                <td
                  v-show="
                    columnList.find((item) => item.key === 'statusCode').visible
                  "
                >
                  {{ item.statusCode }}
                </td>
                <td
                  v-show="
                    columnList.find((item) => item.key === 'receiptNumber')
                      .visible
                  "
                >
                  <a
                    href="#"
                    @click="
                      getReceiptNumber(
                        $event,
                        item.transactionId,
                        item.receiptNumber
                      )
                    "
                    ref="refReceiptNumber"
                    >{{ item.receiptNumber }}</a
                  >
                </td>
                <td
                  v-show="
                    columnList.find((item) => item.key === 'responseDate')
                      .visible
                  "
                >
                  {{ $filters.formatDateTime(item.responseDate) }}
                </td>
                <td
                  v-show="
                    columnList.find((item) => item.key === 'geoposition')
                      .visible
                  "
                >
                  <template v-if="item.geoposition">
                    <a
                      :href="`https://www.google.com/maps/search/?api=1&query=${item.geoposition}`"
                      target="_blank"
                    >
                      {{ item.geoposition.replace(",", ", ") }}
                    </a>
                  </template>
                  <template v-else> -</template>
                </td>
              </tr>
              <template v-if="isLoadingScroll">
                <tr>
                  <td colspan="17">
                    <div class="loading text-center">
                      <trn-loading></trn-loading>
                    </div>
                  </td>
                </tr>
              </template>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!-- Button trigger modal -->
  <button
    type="button"
    data-bs-toggle="modal"
    data-bs-target="#exampleModal"
    ref="modalBtn"
    hidden
  ></button>
  <!-- Modal -->
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ titleModal ? titleModal : "..." }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div
            class="loading text-center"
            v-if="nameModalContent === 'loadingModalContent'"
            :key="keyloadingModalContent"
          >
            <trn-loading></trn-loading>
          </div>
          <trn-transaction-modal-terminal-content
            v-if="nameModalContent === 'terminalId' && dataModalResp"
            :data-modal-resp="dataModalResp"
            key="terminalId"
          ></trn-transaction-modal-terminal-content>
          <trn-load-iframe-scrdoc
            v-if="nameModalContent === 'receiptNumber' && dataModalResp"
            :html-data="dataModalResp"
          ></trn-load-iframe-scrdoc>
          <trn-table-column-settings
            v-if="nameModalContent === 'tableColumnSettings'"
            :columns="columnList"
            @update-columns="changeVisibleColumns"
            page-table-view-name="visibleColumnsTableTransactionList"
          ></trn-table-column-settings>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TrnLoading from "@/components/Loading";
import { parseUrl, stringify } from "query-string";
import { actionTypes } from "@/store/modules/transaction";
import { mapState } from "vuex";
import TrnTransactionModalTerminalContent from "@/components/block-pages/TransactionModalTerminalContent";
import TrnLoadIframeScrdoc from "@/components/LoadIframeScrdoc";
import TrnTableColumnSettings from "@/components/TableColumnSettings.vue";
import { getItemLocalStorage } from "@/helpers/persistanceStorage";
import { Tooltip } from "bootstrap";
import { bg } from "date-fns/locale";

export default {
  name: "TrnTransactions",
  components: {
    TrnTableColumnSettings,
    TrnLoadIframeScrdoc,
    TrnTransactionModalTerminalContent,
    TrnLoading,
  },
  data() {
    return {
      page: 0,
      size: 50,
      sort: "transactionDate,desc",
      transactionId: "",
      panMasked: "",
      terminalId: "",
      approvalCode: "",
      rrn: "",
      operation: "",
      nameModalContent: "",
      titleModal: "",
      operationList: [
        "PURCHASE",
        "REVERSAL",
        "REFUND",
        "REFUND_WITH_OUT_REFERENCE",
        "ZREPORT",
        "PRE_AUTH",
        "CAPTURE",
        "RETURN_WITHOUT_REFERENCE",
        "RETURN_WITH_REFERENCE",
        "PARTIAL_RETURN_WITH_REFERENCE",
        "VOID",
        "BATCH",
        "CASH_PURCHASE",
        "CASH_REFUND",
      ],
      columnList: [
        {
          title: this.$t(`Transaction.table.fraudMonitoring`),
          key: "fraudMonitoring",
          visible: true,
          required: true,
        },
        {
          title: this.$t(`Transaction.table.transactionId`),
          key: "transactionId",
          visible: true,
          required: false,
        },
        {
          title: this.$t(`Transaction.table.operation`),
          key: "operation",
          visible: true,
          required: false,
        },
        {
          title: this.$t(`Transaction.table.panMasked`),
          key: "panMasked",
          visible: true,
          required: false,
        },
        {
          title: this.$t(`Transaction.table.transactionDate`),
          key: "transactionDate",
          visible: true,
          required: true,
        },
        {
          title: this.$t(`Transaction.table.localTransactionDate`),
          key: "localTransactionDate",
          visible: true,
          required: true,
        },
        {
          title: this.$t(`Transaction.table.amount`),
          key: "amount",
          visible: true,
          required: true,
        },
        {
          title: this.$t(`Transaction.table.amountOther`),
          key: "amountOther",
          visible: true,
          required: false,
        },
        {
          title: this.$t(`Transaction.table.currency`),
          key: "currency",
          visible: true,
          required: false,
        },
        {
          title: this.$t(`Transaction.table.terminalId`),
          key: "terminalId",
          visible: true,
          required: false,
        },
        {
          title: this.$t(`Transaction.table.deviceName`),
          key: "deviceName",
          visible: true,
          required: false,
        },
        {
          title: this.$t(`Transaction.table.formFactor`),
          key: "formFactor",
          visible: true,
          required: false,
        },
        {
          title: this.$t(`Transaction.table.approvalCode`),
          key: "approvalCode",
          visible: true,
          required: false,
        },
        {
          title: this.$t(`Transaction.table.responseCode`),
          key: "responseCode",
          visible: true,
          required: false,
        },
        {
          title: this.$t(`Transaction.table.rrn`),
          key: "rrn",
          visible: true,
          required: false,
        },
        {
          title: this.$t(`Transaction.table.statusCode`),
          key: "statusCode",
          visible: true,
          required: true,
        },
        {
          title: this.$t(`Transaction.table.receiptNumber`),
          key: "receiptNumber",
          visible: true,
          required: false,
        },
        {
          title: this.$t(`Transaction.table.responseDate`),
          key: "responseDate",
          visible: true,
          required: false,
        },
        {
          title: this.$t(`Transaction.table.geoposition`),
          key: "geoposition",
          visible: true,
          required: false,
        },
      ],
    };
  },
  computed: {
    bg() {
      return bg;
    },
    dataResp: {
      get() {
        return this.$store.state.transactions.dataResp;
      },
      set(newValue) {
        this.$store.state.transactions.dataResp = newValue;
      },
    },
    ...mapState({
      isLoading: (state) => state.transactions.isLoading,
      isLoadingScroll: (state) => state.transactions.isLoadingScroll,
      // dataResp: (state) => state.transactions.dataResp,
      dataModalResp: (state) => state.transactions.dataModalResp,
      errorResp: (state) => state.transactions.errorResp,
    }),
  },
  methods: {
    fetchTransactions() {
      const route = this.$route;
      const parsedUrl = parseUrl(route.fullPath);
      const sortKey = this.sort.split(",")[0];
      const sortDirection = this.sort.split(",")[1];
      this.changeArrowIcon(sortKey, sortDirection);

      this.$router.replace({
        path: route.path,
        query: {
          page: this.page,
          size: this.size,
          sort: this.sort,
          transactionId: this.transactionId,
          panMasked: this.panMasked,
          terminalId: this.terminalId,
          approvalCode: this.approvalCode,
          rrn: this.rrn,
          operation: this.operation,
        },
      });

      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: this.sort,
        transactionId: this.transactionId,
        panMasked: this.panMasked,
        terminalId: this.terminalId,
        approvalCode: this.approvalCode,
        rrn: this.rrn,
        operation: this.operation,
      });
      const apiUrlWithParams = `${parsedUrl.url}?${stringifiedParams}`;

      this.$store.dispatch(actionTypes.getTransactions, {
        apiUrl: apiUrlWithParams,
      });
    },
    scrollTable(event) {
      const currentEl = event.target;
      let checkScrollEnd =
        currentEl.scrollTop + currentEl.clientHeight + 3 >=
        currentEl.scrollHeight;
      if (checkScrollEnd && this.isLoadingScroll === false) {
        this.loadMoreData();
      }
    },
    loadMoreData() {
      const route = this.$route;
      const parsedUrl = parseUrl(route.fullPath);
      parsedUrl.query.page = parseInt(parsedUrl.query.page) + 1;

      const currentPageQuery = parsedUrl.query.page;
      const totalPages = this.dataResp.totalPages;
      if (currentPageQuery >= totalPages) {
        return;
      }

      this.$router.replace({
        query: {
          page: this.page,
          size: this.size,
          sort: this.sort,
          ...parsedUrl.query,
        },
      });

      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: this.sort,
        ...parsedUrl.query,
      });

      const apiUrlWithParams = `${parsedUrl.url}?${stringifiedParams}`;
      this.$store.dispatch(actionTypes.getTransactionsScrollPush, {
        apiUrl: apiUrlWithParams,
      });
    },
    clickSortCol(event, newSortItem) {
      const scrollTableEl = document.getElementById("divTableTransaction");
      const beforeSortVal = this.sort.split(",");
      const beforeSortKey = beforeSortVal[0];
      const beforeSortDirection = beforeSortVal[1];

      const beforeEl = document.querySelectorAll(
        `[data-col-sort~=${beforeSortKey}]`
      );
      beforeEl[0].children[0].innerHTML = '<i class="bi bi-arrow-down-up"></i>';

      let newSortVal = [newSortItem, ""];

      if (beforeSortDirection === "desc" && beforeSortKey === newSortItem) {
        newSortVal[1] = "asc";
      } else {
        newSortVal[1] = "desc";
      }

      this.sort = newSortVal.join(",");
      this.page = 0;
      scrollTableEl.scrollTo(0, 0);
      this.fetchTransactions();
    },
    changeArrowIcon(sortKey, sortDirection) {
      const targetEl = document.querySelectorAll(`[data-col-sort~=${sortKey}]`);
      if (sortDirection === "asc") {
        targetEl[0].children[0].innerHTML =
          '<i class="bi bi-arrow-up-square"></i>';
      } else {
        targetEl[0].children[0].innerHTML =
          '<i class="bi bi-arrow-down-square"></i>';
      }
    },
    resetFilterForm() {
      this.transactionId = "";
      this.panMasked = "";
      this.terminalId = "";
      this.approvalCode = "";
      this.rrn = "";
      this.dataResp = null;
      this.$refs.dropdownBtnFilter.classList.remove("show");
      this.$refs.dropdownFormFilter.classList.remove("show");
      this.fetchTransactions();
    },
    formFilterTr() {
      this.$refs.dropdownBtnFilter.click();
      this.fetchTransactions();
    },
    getTerminalId(event) {
      const valTermId = event.target.text;
      this.$store.dispatch(actionTypes.getTerminalId, {
        value: valTermId,
      });
      this.titleModal = valTermId;
      this.nameModalContent = "terminalId";
      this.$refs.modalBtn.click();
    },
    getReceiptNumber(event, reqReceiptID, receiptNumber) {
      this.$store.dispatch(actionTypes.getReceiptNumber, {
        value: reqReceiptID,
      });
      this.titleModal = receiptNumber;
      this.nameModalContent = "receiptNumber";
      this.$refs.modalBtn.click();
    },
    showModalViewTableSettings() {
      this.titleModal = this.$t(`uiElements.text.tableColumnSettings`);
      this.nameModalContent = "tableColumnSettings";
      this.$refs.modalBtn.click();
    },
    changeVisibleColumns() {
      const visibleList = getItemLocalStorage(
        "visibleColumnsTableTransactionList"
      );

      if (!visibleList) {
        return;
      }

      this.columnList.forEach((item) => {
        const findItem = visibleList.find((el) => el.key === item.key);
        if (findItem) {
          item.visible = findItem.visible;
        }
      });
    },
  },
  mounted() {
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    });
    this.fetchTransactions();
    this.changeVisibleColumns();
  },
};
</script>

<style scoped></style>

export default {
  "Analytics": {
    "charts": {
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
      "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Count"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
      "refusal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відмова"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успішні"])},
      "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Час"])},
      "title": {
        "declinedAnalytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина відмов"])},
        "formFactorAnalytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пристрій оплати"])},
        "paymentSystemAnalytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платіжні системи"])},
        "rroAnalytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ПРРО"])},
        "statusAnalytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Трансакції"])},
        "transactionActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активність трансакції"])}
      }
    },
    "rro": {
      "table": {
        "appName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва"])},
        "numberOfTransactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість транзакцій"])},
        "ratio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%"])},
        "totalTransactionsAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума транзакцій"])}
      }
    },
    "select": {
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Довільні дати"])},
      "customDateRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дати:"])},
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["за поточний день"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За 30 днів"])},
      "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Часовий проміжок"])}
    }
  },
  "Attestation": {
    "action": {
      "select": {
        "option": {
          "BLOCK_DEVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блокування на пристрій"])},
          "BLOCK_MANUAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual блокування"])},
          "BLOCK_NFC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFC блокування"])},
          "BLOCK_PIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIN блокування"])},
          "BLOCK_QR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR блокування"])},
          "BLOCK_TRANSACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блокування на трансакцію"])},
          "NO_BLOCK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ні блокування"])}
        }
      },
      "table": {
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дія"])},
        "actionWeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вага"])},
        "shortName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Абревіатура"])}
      }
    },
    "sequenceThreats": {
      "table": {
        "attestationActions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дії"])},
        "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])}
      }
    },
    "tab": {
      "actionsThreats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дії / Загрози"])},
      "sequenceThreats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Послідовність загроз"])}
    },
    "threat": {
      "table": {
        "CHANNEL_INTEGRITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цілісність каналів"])},
        "DEBUG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тестування програми"])},
        "EMULATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Емуляція програми"])},
        "GEO_POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Геопозиція"])},
        "INSTALL_SOURCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Встановити джерело"])},
        "INTEGRITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цілісність"])},
        "KEY_ATTESTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атестація ключа"])},
        "ROOT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Права програми"])},
        "VELOCITY_CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частота трансакцій"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дія"])},
        "threat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загроза"])}
      }
    }
  },
  "AttestationHistory": {
    "table": {
      "attestationPhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фаза"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата надсилання"])},
      "declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Результат атестації"])},
      "deviceSn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ідентифікатор пристрою"])},
      "pinChannelIntegrity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цілісність пін-каналу"])},
      "terminalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal ID"])}
    }
  },
  "BusinessDayCalculation": {
    "btn": {
      "generateCalculation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сформувати розрахунок"])}
    },
    "form": {
      "billingCalculationNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Звіт №"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розрахунки за бізнес день"])}
    },
    "tab": {
      "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архів"])},
      "calculation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розрахунок"])}
    },
    "table": {
      "allAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
      "allCalculatedFacilityValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комісія"])},
      "allPercentageFacility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Percentage Facility"])},
      "allStaticFacility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Значення комісії"])},
      "amountMinusFacility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума зарахування"])},
      "calculatedValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума комісії"])},
      "facilityId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID комісії"])},
      "facilityName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва комісії"])},
      "groupFacilityId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер групи комісії"])},
      "groupTerminalNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер терміналу групи"])},
      "numberOfAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Усього трансакцій"])},
      "numberOfPurchases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество покупок"])},
      "numberOfRefund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund"])},
      "numberOfReversal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reversal"])},
      "terminalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal ID"])}
    },
    "text": {
      "billingCalculationNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер розрахунку: "])},
      "commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комісія"])},
      "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата створення: "])},
      "toBeEnrolled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До зарахування"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Усього"])}
    }
  },
  "ChatHistory": {
    "table": {
      "answerIdList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ід-ори відповідей"])},
      "answerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опис проблеми"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
      "sessionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ідентифікатор сесії"])},
      "terminalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal ID"])}
    }
  },
  "Datepicker": {
    "textChoiceRangeDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть дати початку та закінчення звіту"])}
  },
  "Devices": {
    "deviceStatus": {
      "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активний"])},
      "Blocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заблокований"])},
      "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалений"])},
      "Key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key"])},
      "Loan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loan"])},
      "Unblocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розблокований"])}
    },
    "table": {
      "appStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус програми"])},
      "deviceFingerprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відбиток пристрою"])},
      "deviceName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва пристрою"])},
      "deviceSn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ідентифікатор пристрою"])},
      "deviceStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стан пристрою"])},
      "initDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата ініціювання"])},
      "osVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Версія системи"])},
      "terminalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal ID"])}
    }
  },
  "EntityAudit": {
    "table": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата та час"])},
      "entityId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ідентифікатор лога"])},
      "entityName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва лога"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Користувач"])}
    }
  },
  "Footer": {
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["© Авторські права Transplat 2024."])},
    "versionStr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["версія"])}
  },
  "Investigation": {
    "table": {
      "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
      "date_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата закінчення:"])},
      "date_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата початку:"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["description"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "requestId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request ID"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успішно"])},
      "terminal_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal ID"])}
    }
  },
  "IpsCardGroups": {
    "modal": {
      "addNewGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати"])},
      "deleteGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити групу платіжної системи:"])}
    },
    "table": {
      "firstCardNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перші цифри"])},
      "ipsCardGroupId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ідентифікатор"])},
      "ipsName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IPS Назва"])},
      "ipsSymbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Символ платіжної системи"])},
      "noPinLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ліміт без PIN"])},
      "opManual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual маркер"])},
      "opNfc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маркер без NFC"])},
      "opPin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маркер без PIN"])},
      "opQr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маркер без Qr"])}
    }
  },
  "IpsKey": {
    "table": {
      "caHashAlgInd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сa Hash AlgInd"])},
      "caPkAlgInd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сa Pk AlgInd"])},
      "exponentLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Довжина експонентів"])},
      "exponentValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Значення показника"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ідентифікатор запису ключа"])},
      "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Індекс"])},
      "keyExpDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ключова дата придатності"])},
      "modulus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Модуль"])},
      "modulusLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Довжина модуля"])},
      "rid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rid"])},
      "scheme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Схема"])},
      "secureHashAlg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secure HashAlg"])}
    }
  },
  "Merchant": {
    "modal": {
      "deleteMerchant": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити мерчанта"])}
      }
    },
    "table": {
      "bankName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банк"])},
      "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
      "mcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MCC"])},
      "merchantId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ідентифікатор торговця"])},
      "merchantLegalName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Юридична назва торговця"])},
      "merchantLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса"])},
      "merchantName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва торговця"])},
      "taxId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ІПН"])},
      "terminalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal ID"])}
    }
  },
  "MerchantRegistration": {
    "btn": {
      "addFata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Міграція на сервер FATA "])},
      "addNewMerchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати нового торговця"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фільтри"])},
      "reloadPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перезавантажити сторінку"])},
      "sendSmsPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відправити SMS з новим паролем"])},
      "submitMigrateMerchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мігрувати мерчанта"])},
      "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантажити реєстрацію файлу"])}
    },
    "fileForDownloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файли для завантаження"])},
    "fileForDownloadsType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розширення файлу повинно бути: *.trn"])},
    "regLastDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реєстрація за останні дні"])},
    "table": {
      "ReActivationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторний код активації"])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дії"])},
      "addData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина зміни статусу"])},
      "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата створення"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
      "latitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Широта"])},
      "longitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Довгота"])},
      "mcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MCC"])},
      "merchantId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant ID"])},
      "merchantLegalName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва торговця"])},
      "merchantLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фактична адреса торговця"])},
      "merchantLocationEng": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Факт. адрес торговця (англ.)"])},
      "merchantName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва продавця"])},
      "merchantNameEng": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва продавця (англ)"])},
      "merchantRegId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID аккаунту"])},
      "numSecTerm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кільк. підлеглих терміналів"])},
      "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фін. номер телефона"])},
      "radius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Радіус"])},
      "registrationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата реєстрації"])},
      "serviceGroupGroupNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер групи терміналів"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стан"])},
      "taxId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОКПО/ІПН"])},
      "termPriority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Головний термінал"])},
      "terminalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal ID"])},
      "terminalLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса терміналу"])},
      "terminalLocationEng": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса терміналу англ."])},
      "userLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логін торговця"])},
      "zreportTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Час авт. відправки z-report"])}
    },
    "titleModalMerchantStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зміна статусу торговця"])},
    "titleModalMigrateToFata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Примусова міграція мерчанта з сервера Транзенікс на сервер FATA."])},
    "titleModalRegMerchantFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реєстрація торговця через файл"])},
    "toast": {
      "sendSms": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Надіслано на номери:<br> ", _interpolate(_named("numbers"))])}
    },
    "validationRegForm": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введено некоректний email адрес"])},
      "ibanRegex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN введено у невірному форматі"])},
      "mccRegex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Допустимі значення більше 1000 і менше 10 000"])},
      "merchantLocationRegEx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введено недопустимі символи"])},
      "passwordRepeatErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введений повторно пароль не співпадає"])},
      "phoneNumberRegex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть номер телефону в форматі:  +380991234567"])},
      "radiusReg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Допустимі значення більше 0 і менше 1 000 000"])},
      "titude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введено не коректно дані (формат 99.0000)"])}
    }
  },
  "Message": {
    "blockTemplate": {
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дії"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст"])}
    },
    "blockTitle": {
      "org": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повідомлення для Організацій"])},
      "searchMerchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошук продавця"])},
      "searchTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошук терміналу"])},
      "selectedMerchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список обраних продавців"])},
      "selectedTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список обраних терміналів"])},
      "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблон повідомлень"])},
      "terminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повідомлення для Терміналів"])}
    },
    "btn": {
      "clearText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Очистити"])},
      "createMess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити повідомлення"])},
      "saveText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти як шаблон"])},
      "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрати всі знайдені"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відправити"])}
    },
    "form": {
      "countText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["кількість використаних символів: ", _interpolate(_named("num"))])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст"])}
    },
    "toast": {
      "itemUnique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Елемент вже додано до списку"])}
    }
  },
  "Monitoring": {
    "charts": {
      "refusal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відмова"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успішно"])},
      "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Час"])},
      "title": {
        "transactionActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активність трансакції"])}
      }
    },
    "health": {
      "dbConnectionHealth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стан підключення до БД"])},
      "hsmConnectionHealth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стан підключення HSM"])},
      "isoAuthConnectionHealth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стан підключення ISO"])},
      "isoTessConnectionHealth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стан підключення ISO Tess"])},
      "serverHealth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стан сервера"])},
      "smppConnectionHealth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стан підключення SMPP"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стан сервісів"])}
    }
  },
  "Products": {
    "modal": {
      "addNewProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати новий продукт"])}
    },
    "table": {
      "ipsCardGroupId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ідентифікатор платіжної системи"])},
      "ipsSymbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Символ платіжної системи"])},
      "productId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ідентифікатор продукту"])},
      "productName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва продукту"])},
      "rangeBegin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Початковий діапазон"])},
      "rangeEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кінцевий діапазон"])}
    }
  },
  "ReceiptSendAudits": {
    "table": {
      "receiptNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер чека"])},
      "receiptSendChannel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канал відправлення"])},
      "recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одержувач"])},
      "sendDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
      "successful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
      "transactionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер трансакції"])}
    }
  },
  "RegularReports": {
    "activeTerminals": {
      "table": {
        "dateTimeInit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date time init"])},
        "lastTransactionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата останньої трансакції"])},
        "lastUpdateDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата останнього оновлення"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "terminalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal ID"])}
      }
    },
    "form": {
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Експорт звіту"])},
      "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Період: "])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть тип звіту:"])},
      "type2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип:"])}
    },
    "lifecycle": {
      "table": {
        "fieldName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я поля"])},
        "newValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нове значення"])},
        "oldValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Старе значення"])}
      }
    },
    "select": {
      "export": {
        "CSV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV"])},
        "PDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF"])},
        "XLS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XLS"])}
      },
      "formPeriod": {
        "ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За весь час"])},
        "DAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["День"])},
        "MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Місяць"])},
        "WEEK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неділя"])}
      },
      "type": {
        "PURCHASE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PURCHASE"])},
        "REFUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REFUND"])},
        "REVERSAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REVERSAL"])},
        "ZREPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZREPORT"])}
      }
    },
    "terminalsByGroups": {
      "table": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість терміналів"])},
        "numberOfSecondaryTerminals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numberOfSecondaryTerminals"])}
      }
    },
    "text": {
      "toStartGenReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для початку формування звіту введіть: "])}
    },
    "transactionsByMerchant": {
      "table": {
        "refundAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["refundAmount"])}
      }
    },
    "typeReportList": {
      "activeTerminals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активні термінали"])},
      "merchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість терміналів на підприємстві"])},
      "terminalLifecycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Звіт про зміни життєвого циклу терміналу"])},
      "terminalRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реєстрація терміналу"])},
      "terminalsByGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість терміналів за групами"])},
      "transactionsByBlame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Звіт про трансакції за типами операцій на підприємстві"])},
      "transactionsByIps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Звіт про трансакції у розрізі платіжних систем"])},
      "transactionsByMerchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Звіт про трансакції у розрізі підприємства"])},
      "transactionsByTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Звіт про трансакції у розрізі терміналу"])},
      "transactionsByType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Звіт про трансакції за типами операцій"])}
    }
  },
  "RegulatoryCommissions": {
    "btn": {
      "makeWiring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вивантажити звіт"])}
    },
    "form": {
      "messageEng": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст повідомлення англійською"])},
      "messageRu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст повідомлення на російській"])},
      "messageUkr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст повідомлення \"Українською\""])}
    },
    "tab": {
      "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс"])},
      "commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комісії"])},
      "enrollments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарахування"])},
      "generalFinBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загальна фінансова книга"])},
      "merchants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Торговці"])},
      "proceduralNotices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування сповіщень"])},
      "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Звіт про вивантаження трансакцій"])}
    },
    "table": {
      "accrual": {
        "merchantDebtAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До зарахування"])},
        "shipmentReportId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID звіту зарахування"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проведено"])}
      },
      "balance": {
        "ibanPayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN платника"])},
        "ibanReceiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одержувача IBAN"])},
        "operationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип операції"])},
        "paymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платіжні реквізити"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс"])}
      },
      "commission": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
        "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])}
      },
      "generalFinBook": {
        "dateCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date created"])},
        "incomingTransactionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ідентифікатор вхідної трансакції"])}
      },
      "merchant": {
        "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс"])},
        "creditPeriodValueWithType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кредитний період"])},
        "fcmMessageTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FCM типи повідомлень"])},
        "merchantId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant ID"])},
        "overdraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Овердрафт"])}
      },
      "transaction": {
        "bankFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комісія банку"])},
        "dateTransferred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата проведення"])},
        "fataDebt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума FATA"])},
        "fataFeeRegulatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регламентна комісія FATA"])},
        "fataFeeTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Трансакційна комісія FATA"])},
        "isMergeWithBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "isTransferred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проведено"])},
        "merchantDebt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума продавця"])},
        "referencedId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID звіту"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всього"])},
        "totalMinusBankFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума FATA банку"])}
      }
    },
    "text": {
      "searchMerchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошук продавця по:"])},
      "typeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["почніть вводити текст..."])}
    }
  },
  "Schedule": {
    "btn": {
      "changeStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змінити статус"])},
      "forcedStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Примусовий запуск"])}
    },
    "table": {
      "cron": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cron"])},
      "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
      "lastSuccessRunTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Останній запуск"])},
      "nextScheduledDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наступний час запуску"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])}
    }
  },
  "Settings": {
    "Banks": {
      "table": {
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
        "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сайт банку"])},
        "mfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["МФО"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
        "taxId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОКПО/ИНН"])}
      }
    },
    "Installations": {
      "form": {
        "amountTimeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Час для введення суми (секунд)"])},
        "appActiveTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Час активності програми (хвилин)"])},
        "appActiveTimeInMinutesMs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Час сесії мобільного додатку (хв)"])},
        "attestationTimeMax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Макс. час для випадкової атестації (годинник/хвилини)"])},
        "attestationTimeMin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["мін. час для випадкової атестації (годин/хвилини)"])},
        "beginCardMask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість цифр картки на початку"])},
        "cardMaskSymbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немаскований текст карти"])},
        "endCardMask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість цифр картки в кінці"])},
        "isoClientCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сертифікат клієнта"])},
        "isoIntermediateCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проміжний сертифікат"])},
        "isoNetworkClientPk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приватний ключ"])},
        "isoRootCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кореневий сертифікат"])},
        "manualTimeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Час очікування Manual-pay (секунд)"])},
        "manualTimeoutMs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Час очікування Manual-pay (мілісекунд)"])},
        "maxReceiptNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальний номер чека"])},
        "minReceiptNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мінімальний номер чека"])},
        "nfcTimeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Час очікування NFC-pay (секунд)"])},
        "nfcTimeoutInMsMs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Час прикладання картки для NFC"])},
        "numRefundAttemptsForTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість спроб повернення грошей"])},
        "pendingNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К-ть спроб запиту (штук)"])},
        "pendingTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тимчасовий інтервал запитів (секунд)"])},
        "phoneMask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблон відображення тел."])},
        "proposalEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Електронна пошта для пропозицій"])},
        "questionEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Електронна пошта для запитань"])},
        "receiptHost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблон відображення посилання на е-чек"])},
        "refundLimitDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість днів для повернення грошей"])},
        "supervisorCardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Системна картка"])},
        "technicalMerchantId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Системний мерчант-ID"])},
        "technicalTerminalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Системний термінал-ID"])},
        "timeZReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Час Z-звіту"])}
      },
      "text": {
        "networkCertificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мережеві сертифікати"])}
      }
    },
    "ReceiptTemplate": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактор"])},
      "table": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва"])},
        "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер"])},
        "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стилі"])},
        "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблон"])}
      },
      "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перегляд"])}
    },
    "TerminalFacility": {
      "btn": {
        "addFullTariff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати повний тариф"])},
        "addLoyaltyTariff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати тариф лояльності"])},
        "addProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити профіль"])},
        "deleteProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалення профілю"])},
        "deleteTariffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Tariffs"])},
        "updateProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змінити профіль: "])}
      },
      "modal": {
        "addFullTariff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створення повного тарифу"])},
        "addLoyaltyTariff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створення тарифу лояльності"])},
        "changeTariff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зміна тарифу: "])},
        "choiceProfileTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть профіль для переходу"])},
        "choiceTariffByType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть тариф для типу операції"])},
        "deleteId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID профілю, що видаляється"])}
      },
      "tab": {
        "tariffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тарифи"])},
        "tariffsProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профілі тарифу"])}
      },
      "table": {
        "beginDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата початку"])},
        "choiceTariffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#"])},
        "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата створення"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опис"])},
        "expiredDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата закінчення терміну"])},
        "fixedFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фіксована плата"])},
        "groupFacility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профіль"])},
        "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID тарифу"])},
        "limitToApplyPercentageFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мінімальна комісія з %"])},
        "loyaltyPeriodType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Період лояльності"])},
        "loyaltyPeriodValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Значення періоду лояльності"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я"])},
        "nextFacilityId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тариф переходу"])},
        "noTariffProfileData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для цього профілю, тарифи не вибрані"])},
        "originType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип тарифу"])},
        "percentageFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відсоткова комісія"])},
        "relatedLoyaltyFacilityId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прикріплений тариф лояльності"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип операції"])}
      }
    },
    "UserRoles": {
      "btn": {
        "addRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати нову роль"])}
      },
      "permissionsAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступні дозволи"])},
      "permissionsSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обрані дозволи користувача "])},
      "table": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опис"])},
        "roleAuthorities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибір дозволів"])},
        "roleCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код ролі"])},
        "roleTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва ролі"])}
      }
    }
  },
  "SetupConfig": {
    "IpsCardGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Група карток IPS"])},
    "Products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продукти"])},
    "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Користувачі"])},
    "UserRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ролі користувача"])},
    "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банк"])},
    "currencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта"])},
    "generalConfiguration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загальна конфігурація"])},
    "serviceGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервіс група"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конфігуратор установки системи"])}
  },
  "Terminal": {
    "btn": {
      "addSubTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати підлеглий термінал"])}
    },
    "formSubTerminal": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код активації"])}
    },
    "formUpdateTID": {
      "addData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додаткова інформація"])},
      "repeatRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторна реєстрація"])},
      "zreportEnabledAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z-звіт по дням тижня"])}
    },
    "selectStatus": {
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активний"])},
      "BLOCK_ATTESTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заблоковано атестацією"])},
      "BLOCK_BANK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заблокировано банком"])},
      "BLOCK_CLIENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заблоковано клієнтом"])},
      "BLOCK_MANUAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заблоковано менеджером"])},
      "BLOCK_SYSTEM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заблоковано системою"])},
      "DELETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалений"])},
      "NOT_READY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не готовий"])}
    },
    "table": {
      "bankName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банк"])},
      "currencyCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта"])},
      "dateTimeInit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата реєстрації"])},
      "deviceName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пристрій"])},
      "groupNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер групи"])},
      "lastTransactionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата ініціалізаціі"])},
      "lastUpdateDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата оновлення"])},
      "masterTerminalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Terminal ID"])},
      "mcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MCC"])},
      "merchantId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant ID"])},
      "merchantLegalName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва торговця"])},
      "merchantName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва торговця"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
      "terminalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal ID"])},
      "terminalLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса установки терміналу"])},
      "terminalLocationEng": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса установки терміналу (англ.)"])},
      "zReportAuto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авто Z-звіт"])}
    }
  },
  "TerminalGroups": {
    "btn": {
      "changeGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змінити групу"])},
      "changeNumberGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змінити номер групи"])}
    },
    "modal": {
      "addNewGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати нову групу"])},
      "deleteNumberGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалення групи"])},
      "editGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змінити групу: "])}
    },
    "table": {
      "bankId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank ID"])},
      "bankName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банк"])},
      "currencyCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта"])},
      "geoPosition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Визначення геопозиції"])},
      "groupFacilityId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Facility ID"])},
      "groupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва групи"])},
      "groupNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер групи"])},
      "ipsCardGroupIdList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прийом карт"])},
      "menuItemList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опції меню мобільного додатку"])},
      "noPinLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ліміт без ПІНу"])},
      "numberOfSecondaryTerminals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість підлеглих терміналів"])},
      "oneTransactionLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ліміт на одну трансакцію"])},
      "opCash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операція Cash"])},
      "opCreateSubordinationTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можливість створення підлеглих терміналів"])},
      "opManual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операція Entry-Mode"])},
      "opNfc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата Pay-NFC"])},
      "opPinAmex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введення пін-коду для Amex"])},
      "opPinDiscover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введення пін-коду для Discover"])},
      "opPinMastercard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введення пін-коду для Mastercard"])},
      "opPinProstir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введення пін-коду для Простір"])},
      "opPinVisa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введення пін-коду для Visa"])},
      "opPurchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операції оплати"])},
      "opQr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операція Pay-QR"])},
      "opRefund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операція повернення"])},
      "opReversal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відміна трансакції"])},
      "opSingleTapAndPin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтримка Single Tap and PIN"])},
      "opTips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чайові"])},
      "productIdList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ідентифікатор продукту"])},
      "productIds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ідентифікатор продукту"])},
      "productName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва продукту"])},
      "receiptSendChannelIdList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канал надсилання чеку"])},
      "receiptTemplateId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблон чека"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
      "toGroupNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перенести до групи номер"])},
      "totalAmountLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ліміт загальної суми за день"])},
      "totalCountLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обмеження загальної кількості трансакцій за день"])},
      "totalLimitPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість трансакцій за день"])},
      "transactionGeoposition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Геопозиція трансакції"])},
      "velocityCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість трансакцій у заданий період часу"])},
      "velocityPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Період швидкості"])},
      "velocityTimeUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одиниця часу швидкості"])}
    }
  },
  "TerminalKeys": {
    "btn": {
      "keyManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Примусовий менеджмент ключів"])},
      "keyManagerAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запуск для всих ключів"])}
    },
    "tab": {
      "allPosKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Усі POS-ключі"])},
      "termKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ключі терміналів"])}
    },
    "table": {
      "checkValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контрольне значення"])},
      "effDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата активації ключа"])},
      "expDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата закінчення ключа"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ід-р запису ключа"])},
      "keyType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип ключа"])},
      "keyValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Значення ключа під LMK"])},
      "refCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ід-р терміналу"])},
      "statCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стан"])},
      "terminalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal ID"])}
    }
  },
  "TmsKey": {
    "table": {
      "checkValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контрольне значення"])},
      "effDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата активації ключа"])},
      "expDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата закінчення ключа"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ідентифікатор запису ключа"])},
      "keyType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип ключа"])},
      "keyValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Значення ключа під LMK"])},
      "refCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посилання"])},
      "statCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])}
    }
  },
  "Transaction": {
    "table": {
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
      "amountOther": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дод. сума"])},
      "approvalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код авторизації"])},
      "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта"])},
      "deviceName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пристрій"])},
      "formFactor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Форм-фактор"])},
      "fraudMonitoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["F"])},
      "fraudMonitoringFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фрод статус"])},
      "geoposition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Геолокація"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мова"])},
      "localTransactionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Локальна дата трансакції"])},
      "merchantId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant ID"])},
      "operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип операції"])},
      "panMasked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маска карти"])},
      "receiptNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер чека"])},
      "responseCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код відповіді"])},
      "responseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата відповіді"])},
      "rrn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RRN"])},
      "statusCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
      "terminalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID терміналу"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Трансакції"])},
      "transactionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата трансакції"])},
      "transactionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер трансакції"])}
    }
  },
  "TrnReportsSettlement": {
    "TerminalTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Звіт за терміналами"])},
    "TransactionTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Звіт по трансакціям"])},
    "amountAllTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума всіх трансакцій"])},
    "amountBankCommission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount bank commission"])},
    "amountPinTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума Pin трансакцій"])},
    "amountPurchaseTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість Purchase трансакцій"])},
    "amountRefundTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума Refund трансакцій"])},
    "amountReversalTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума Reversal трансакцій"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ідентифікатор"])},
    "initDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата створення"])},
    "quantityActiveTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість активних терміналів"])},
    "quantityAllTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість усіх терміналів"])},
    "quantityAllTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість усіх трансакцій"])},
    "quantityDeclinedTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["кількість відхилених трансакцій"])},
    "quantityInactiveTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість неактивних терміналів"])},
    "quantityLoginTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість заставних терміналів"])},
    "quantityPinTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість ПІН трансакцій"])},
    "quantityReactivateTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість повторно активованих терміналів"])},
    "quantityRefundTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість Refund трансакцій"])},
    "quantityRegisterFullTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість повністю зареєстрованих терміналів"])},
    "quantityRegisterWaitTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість зареєстрованих терміналів в очікуванні"])},
    "quantityReversalTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість Reversal трансакцій"])},
    "quantitySuccessTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість успішних трансакцій"])},
    "quantityTurnActiveTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість Turn активних терміналів"])},
    "quantityTurnInactiveTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість повернутих неактивних терміналів"])}
  },
  "Users": {
    "addUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зареєструвати користувача"])},
    "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зміна поролю"])},
    "table": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прізвище"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
      "userRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роль"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логін"])}
    }
  },
  "Validations": {
    "checkSendChannelRequiredList": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Оберіть хоча б один обов'язковий канал надсилання чеків: ", _interpolate(_named("chList"))])},
    "isForbidToChangePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль протермінований, зверніться для зміни до адміністратора"])},
    "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Максимально дозволена довжина символів: ", _interpolate(_named("num")), " "])},
    "maxValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Максимально допустиме значення: ", _interpolate(_named("num"))])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Мінімально допустима довжина символів: ", _interpolate(_named("num"))])},
    "minValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Мінімально допустиме значення: ", _interpolate(_named("num"))])},
    "notChosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вибрано"])},
    "onlyLettersNumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Допустимі тільки букви і цифри латинського алфавіту"])},
    "onlyNumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можливо вводити тільки цифри"])},
    "passRule1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вимоги до паролю:<br> Мінімум одна цифра.<br>  Мінімум одна велика літера.<br> Мінімум один спеціальний символ (", _interpolate(_named("specChar")), ").<br> Без пробілів або символів пропуску.<br>"])},
    "passRule2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість однакових повторень символів обмежена до 3 підряд."])},
    "passwordExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль протермінований, введіть новий."])},
    "passwordExpiresSoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Термін дії паролю скоро сплине, змініть пароль."])},
    "passwordRepeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть повторно пароль"])},
    "reEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введена некоректна електронна адреса"])},
    "reNumAndDot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дозволені для вводу тільки цифри та крапки"])},
    "reTextAndNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введено не дозволені символи. Доступні для вводу тільки букви і цифри."])},
    "reTextAndNumDot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введено не дозволені символи. Доступні для вводу тільки букви і цифри та  (\",\" \".\" \"-\")."])},
    "reTextLatin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дозволено вводити тільки символи латиниці"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле є обов'язковим для заповнення"])},
    "userPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть пароль"])}
  },
  "VendorRSO": {
    "table": {
      "addVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додавання нового"])},
      "aidlLibVersionCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AILD версія"])},
      "appName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва додатку"])},
      "createDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата створення"])},
      "expiredDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата закінчення"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Місяць"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва"])},
      "packageName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва пакету"])},
      "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проект"])},
      "shortName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скорочена назва"])},
      "vendorCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код постачальника"])},
      "vendorId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Версія"])},
      "versionCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код версії"])},
      "versionName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва версії"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рік"])}
    }
  },
  "Version": {
    "table": {
      "expDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата завершення"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва"])},
      "updateDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата реєстрації."])},
      "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Версія"])}
    }
  },
  "ZReportTransactions": {
    "table": {
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
      "approvalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код авторизації"])},
      "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість"])},
      "localTransactionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Локальна Дата/Час"])},
      "merchantName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant ID"])},
      "operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип операції"])},
      "responseCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код відповіді"])},
      "responseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Час відповіді"])},
      "responseMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст відповіді"])},
      "rrn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RRN"])},
      "statusCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
      "terminalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal ID"])},
      "transactionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата/Час"])}
    }
  },
  "lang": {
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Русский"])},
    "uk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Українська"])}
  },
  "left-menu": {
    "analytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аналітика"])},
    "attestationHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Історія атестацій"])},
    "businessDayCalculation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розрахунок бізнес дня"])},
    "chatHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Історія чату"])},
    "devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пристрої"])},
    "investigation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розслідування"])},
    "merchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Торговці"])},
    "merchantRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реєстрація торговця"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сповіщення"])},
    "monitoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Моніторинг"])},
    "receiptSendAudit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запити чеків"])},
    "regularReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регламентні звіти"])},
    "regulatoryCommissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фінансова компанія"])},
    "reportsSettlement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розрахунки з банком"])},
    "serviceGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Групи терміналів"])},
    "terminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Термінали"])},
    "terminalKeys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ключі терміналів"])},
    "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Трансакції"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Користувачі"])},
    "zReportTransactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z-Звіти"])}
  },
  "settings-menu": {
    "VendorRSO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вендор РРО"])},
    "attestation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атестаційні параметри"])},
    "banks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банки"])},
    "changeAudit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аудит змін"])},
    "entityAudit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зміни у системі"])},
    "installations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Установки"])},
    "ipsCardGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платіжні системи"])},
    "ipsKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ключі платіжної системи"])},
    "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продукти"])},
    "receiptTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблон чеку"])},
    "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розклад"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування системи"])},
    "terminalFacility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тарифи"])},
    "tmsKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ключі системи"])},
    "userRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ролі"])},
    "vendorRSO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вендор РРО"])},
    "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Версії"])}
  },
  "test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["test"])},
  "uiElements": {
    "btn": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати"])},
      "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Застосувати"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змінити"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрити"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити"])},
      "deleteAllFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити всі файли"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантажити"])},
      "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фільтри"])},
      "resetFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скинути фільтри"])},
      "run": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запустити"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відправити"])},
      "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увійти"])}
    },
    "tableUpload": {
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дія"])},
      "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я файлу"])},
      "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розмір"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])}
    },
    "text": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати"])},
      "columnName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва колонки"])},
      "countItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість записів"])},
      "currencyCode": {
        "0980": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["грн."])}
      },
      "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Деталі"])},
      "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вимкнути"])},
      "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увімкнено"])},
      "listEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список порожній"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логін"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ні"])},
      "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає даних"])},
      "noDataSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За цим пошуком немає результатів"])},
      "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["із"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показувати"])},
      "signInPanel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вхід до кабінету"])},
      "tableColumnSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування вигляду таблиці"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Так"])}
    },
    "toast": {
      "err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
      "errSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка збереження"])},
      "sendSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успішно відправлено"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успіх"])},
      "successDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успішно видалено"])},
      "successSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успішно збережено"])}
    }
  }
}